import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  class: "fill-[#164193]",
  viewBox: "0 0 136.1 28.3"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M124 6c-5.5.4-9.9 4.9-10.3 10.4-.4 6.5 4.7 11.9 11.1 11.9 2.3 0 5.4-1.2 6.8-3.6V28h4.3V17.4c.2-7-5.5-11.9-11.9-11.4m7.3 11.5c-.2 3.7-3.4 6.5-7.2 6-2.9-.4-5.2-2.7-5.6-5.6-.4-3.9 2.6-7.2 6.4-7.2 3.4 0 6.2 2.7 6.4 6.1zM100.4 6c-5.9.2-10.9 4.7-10.9 11.3 0 6 4.9 11.1 11.2 11.1 4.4 0 8.7-2.6 10.4-6.9l-4.3-1.3c-.8 1.8-2.7 3.3-4.8 3.7-3 .6-5.8-1.3-6.9-3.3l16.7-4.6c-.6-4.4-4.3-10.2-11.4-10m-6.2 10.7c0-2.3 1.4-5 4.4-6.1 3.4-1.3 6.4.4 7.7 2.8zM63.6 10.5V5.9C59.4 6 58 8 57.3 9.2V6.7h-4.8V28h4.9V17.5c.1-4.9 2.9-6.8 6.2-7M19.6 19.4 5 2.3H0V28h5.1V10.8L19.9 28h4.6V2.3h-4.9zM86.5 0h-4.6v8.4c-1.1-1.4-4.5-2.6-7.4-2.3-5.5.4-9.9 4.9-10.3 10.4-.4 6.5 4.7 11.9 11.1 11.9 2.4 0 5.7-1.1 6.8-3.2V28h4.3V0zm-4.7 17.5c-.2 3.7-3.4 6.5-7.2 6-2.9-.4-5.2-2.7-5.6-5.6-.4-3.9 2.6-7.2 6.4-7.2 3.4 0 6.2 2.7 6.4 6.1zM38.5 6c-6.2 0-11.2 5-11.2 11.2s5 11.2 11.2 11.2 11.2-5 11.2-11.2S44.7 6 38.5 6m0 17.6c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4M106.3 13.4" }, null, -1)
  ])))
}
export default { render: render }